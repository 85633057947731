define("discourse/plugins/chat/discourse/components/chat/list/item", ["exports", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Item = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    {{yield @item}}
  */
  {
    "id": "k+J7gaM/",
    "block": "[[[18,2,[[30,1]]]],[\"@item\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/list/item.js",
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = Item;
});