define("discourse/plugins/chat/discourse/components/reviewable-chat-message", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/routing", "@ember/service", "discourse/components/reviewable-created-by", "discourse/components/reviewable-post-header", "discourse-common/helpers/html-safe", "discourse-common/helpers/i18n", "truth-helpers/helpers/or", "discourse/plugins/chat/discourse/components/channel-title", "discourse/plugins/chat/discourse/models/chat-channel", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _helper, _routing, _service, _reviewableCreatedBy, _reviewablePostHeader, _htmlSafe, _i18n, _or, _channelTitle, _chatChannel, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ReviewableChatMessage = _exports.default = (_class = (_class2 = class ReviewableChatMessage extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "store", _descriptor, this);
      _initializerDefineProperty(this, "chatChannelsManager", _descriptor2, this);
    }
    get channel() {
      if (!this.args.reviewable.chat_channel) {
        return;
      }
      return _chatChannel.default.create(this.args.reviewable.chat_channel);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if this.channel}}
        <div class="flagged-post-header">
          <LinkTo
            @route="chat.channel.near-message"
            @models={{array
              this.channel.slugifiedTitle
              this.channel.id
              @reviewable.target_id
            }}
          >
            <ChannelTitle @channel={{this.channel}} />
          </LinkTo>
        </div>
      {{/if}}
  
      <div class="post-contents-wrapper">
        <ReviewableCreatedBy
          @user={{@reviewable.target_created_by}}
          @tagName=""
        />
        <div class="post-contents">
          <ReviewablePostHeader
            @reviewable={{@reviewable}}
            @createdBy={{@reviewable.target_created_by}}
            @tagName=""
          />
  
          <div class="post-body">
            {{htmlSafe
              (or @reviewable.payload.message_cooked @reviewable.cooked)
            }}
          </div>
  
          {{#if @reviewable.payload.transcript_topic_id}}
            <div class="transcript">
              <LinkTo
                @route="topic"
                @models={{array "-" @reviewable.payload.transcript_topic_id}}
                class="btn btn-small"
              >
                {{i18n "review.transcript.view"}}
              </LinkTo>
            </div>
          {{/if}}
  
          {{yield}}
        </div>
      </div>
    
  */
  {
    "id": "GNO5Z3K4",
    "block": "[[[1,\"\\n\"],[41,[30,0,[\"channel\"]],[[[1,\"      \"],[10,0],[14,0,\"flagged-post-header\"],[12],[1,\"\\n        \"],[8,[32,0],null,[[\"@route\",\"@models\"],[\"chat.channel.near-message\",[28,[32,1],[[30,0,[\"channel\",\"slugifiedTitle\"]],[30,0,[\"channel\",\"id\"]],[30,1,[\"target_id\"]]],null]]],[[\"default\"],[[[[1,\"\\n          \"],[8,[32,2],null,[[\"@channel\"],[[30,0,[\"channel\"]]]],null],[1,\"\\n        \"]],[]]]]],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n    \"],[10,0],[14,0,\"post-contents-wrapper\"],[12],[1,\"\\n      \"],[8,[32,3],null,[[\"@user\",\"@tagName\"],[[30,1,[\"target_created_by\"]],\"\"]],null],[1,\"\\n      \"],[10,0],[14,0,\"post-contents\"],[12],[1,\"\\n        \"],[8,[32,4],null,[[\"@reviewable\",\"@createdBy\",\"@tagName\"],[[30,1],[30,1,[\"target_created_by\"]],\"\"]],null],[1,\"\\n\\n        \"],[10,0],[14,0,\"post-body\"],[12],[1,\"\\n          \"],[1,[28,[32,5],[[28,[32,6],[[30,1,[\"payload\",\"message_cooked\"]],[30,1,[\"cooked\"]]],null]],null]],[1,\"\\n        \"],[13],[1,\"\\n\\n\"],[41,[30,1,[\"payload\",\"transcript_topic_id\"]],[[[1,\"          \"],[10,0],[14,0,\"transcript\"],[12],[1,\"\\n            \"],[8,[32,0],[[24,0,\"btn btn-small\"]],[[\"@route\",\"@models\"],[\"topic\",[28,[32,1],[\"-\",[30,1,[\"payload\",\"transcript_topic_id\"]]],null]]],[[\"default\"],[[[[1,\"\\n              \"],[1,[28,[32,7],[\"review.transcript.view\"],null]],[1,\"\\n            \"]],[]]]]],[1,\"\\n          \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n        \"],[18,2,null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@reviewable\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/reviewable-chat-message.js",
    "scope": () => [_routing.LinkTo, _helper.array, _channelTitle.default, _reviewableCreatedBy.default, _reviewablePostHeader.default, _htmlSafe.default, _or.default, _i18n.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatChannelsManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "channel", [_tracking.cached], Object.getOwnPropertyDescriptor(_class.prototype, "channel"), _class.prototype)), _class);
});