define("discourse/plugins/chat/discourse/components/chat/message-creator/user", ["exports", "@glimmer/component", "@ember/service", "discourse/helpers/user-status", "discourse-i18n", "truth-helpers/helpers/gt", "truth-helpers/helpers/not", "discourse/plugins/chat/discourse/components/chat-user-avatar", "discourse/plugins/chat/discourse/components/chat-user-display-name", "@ember/template-factory", "@ember/component"], function (_exports, _component, _service, _userStatus, _discourseI18n, _gt, _not, _chatUserAvatar, _chatUserDisplayName, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatableUser = _exports.default = (_class = (_class2 = class ChatableUser extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "currentUser", _descriptor, this);
      _defineProperty(this, "disabledUserLabel", _discourseI18n.default.t("chat.new_message_modal.disabled_user"));
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        class="chat-message-creator__chatable -user"
        data-disabled={{not @item.enabled}}
      >
        <ChatUserAvatar @user={{@item.model}} @interactive={{false}} />
        <ChatUserDisplayName @user={{@item.model}} />
  
        {{#if (gt @item.tracking.unreadCount 0)}}
          <div class="unread-indicator -urgent"></div>
        {{/if}}
  
        {{userStatus @item.model currentUser=this.currentUser}}
  
        {{#unless @item.enabled}}
          <span class="chat-message-creator__chatable -disabled-chat">
            {{this.disabledUserLabel}}
          </span>
        {{/unless}}
      </div>
    
  */
  {
    "id": "AoAlUkVm",
    "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"chat-message-creator__chatable -user\"],[15,\"data-disabled\",[28,[32,0],[[30,1,[\"enabled\"]]],null]],[12],[1,\"\\n      \"],[8,[32,1],null,[[\"@user\",\"@interactive\"],[[30,1,[\"model\"]],false]],null],[1,\"\\n      \"],[8,[32,2],null,[[\"@user\"],[[30,1,[\"model\"]]]],null],[1,\"\\n\\n\"],[41,[28,[32,3],[[30,1,[\"tracking\",\"unreadCount\"]],0],null],[[[1,\"        \"],[10,0],[14,0,\"unread-indicator -urgent\"],[12],[13],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[1,[28,[32,4],[[30,1,[\"model\"]]],[[\"currentUser\"],[[30,0,[\"currentUser\"]]]]]],[1,\"\\n\\n\"],[41,[51,[30,1,[\"enabled\"]]],[[[1,\"        \"],[10,1],[14,0,\"chat-message-creator__chatable -disabled-chat\"],[12],[1,\"\\n          \"],[1,[30,0,[\"disabledUserLabel\"]]],[1,\"\\n        \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@item\"],false,[\"if\",\"unless\"]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/message-creator/user.js",
    "scope": () => [_not.default, _chatUserAvatar.default, _chatUserDisplayName.default, _gt.default, _userStatus.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "currentUser", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class);
});