define("discourse/plugins/chat/discourse/components/chat/scroll-to-bottom-arrow", ["exports", "discourse/components/d-button", "discourse/helpers/concat-class", "discourse-common/helpers/d-icon", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _dButton, _concatClass, _dIcon, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ScrollToBottomArrow = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <div class="chat-scroll-to-bottom">
      <DButton
        class={{concatClass
          "btn-flat"
          "chat-scroll-to-bottom__button"
          (if @isVisible "visible")
        }}
        @action={{@onScrollToBottom}}
      >
        <span class="chat-scroll-to-bottom__arrow">
          {{dIcon "arrow-down"}}
        </span>
      </DButton>
    </div>
  
  */
  {
    "id": "X4eUUl8z",
    "block": "[[[1,\"\\n  \"],[10,0],[14,0,\"chat-scroll-to-bottom\"],[12],[1,\"\\n    \"],[8,[32,0],[[16,0,[28,[32,1],[\"btn-flat\",\"chat-scroll-to-bottom__button\",[52,[30,1],\"visible\"]],null]]],[[\"@action\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n      \"],[10,1],[14,0,\"chat-scroll-to-bottom__arrow\"],[12],[1,\"\\n        \"],[1,[28,[32,2],[\"arrow-down\"],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[\"@isVisible\",\"@onScrollToBottom\"],false,[\"if\"]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat/scroll-to-bottom-arrow.js",
    "scope": () => [_dButton.default, _concatClass.default, _dIcon.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ScrollToBottomArrow;
});