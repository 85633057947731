define("discourse/plugins/chat/discourse/components/chat-message-thread-indicator", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/render-modifiers/modifiers/will-destroy", "@ember/service", "discourse/helpers/concat-class", "discourse/helpers/format-date", "discourse/helpers/replace-emoji", "discourse-common/helpers/html-safe", "discourse-common/helpers/i18n", "discourse-common/lib/get-url", "discourse-common/utils/decorators", "discourse/plugins/chat/discourse/components/chat-thread-participants", "discourse/plugins/chat/discourse/components/chat-user-avatar", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _object, _didInsert, _willDestroy, _service, _concatClass, _formatDate, _replaceEmoji, _htmlSafe, _i18n, _getUrl, _decorators, _chatThreadParticipants, _chatUserAvatar, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ChatMessageThreadIndicator = _exports.default = (_class = (_class2 = class ChatMessageThreadIndicator extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "capabilities", _descriptor, this);
      _initializerDefineProperty(this, "chat", _descriptor2, this);
      _initializerDefineProperty(this, "chatStateManager", _descriptor3, this);
      _initializerDefineProperty(this, "router", _descriptor4, this);
      _initializerDefineProperty(this, "site", _descriptor5, this);
      _initializerDefineProperty(this, "isActive", _descriptor6, this);
    }
    get interactiveUser() {
      return this.args.interactiveUser ?? true;
    }
    setup(element1) {
      this.element = element1;
      if (this.capabilities.touch) {
        this.element.addEventListener("touchstart", this.onTouchStart, {
          passive: true
        });
        this.element.addEventListener("touchmove", this.cancelTouch, {
          passive: true
        });
        this.element.addEventListener("touchend", this.onTouchEnd);
        this.element.addEventListener("touchCancel", this.cancelTouch);
      }
      this.element.addEventListener("mousedown", this.openThread, {
        passive: true
      });
      this.element.addEventListener("keydown", this.openThread, {
        passive: true
      });
    }
    teardown() {
      if (this.capabilities.touch) {
        this.element.removeEventListener("touchstart", this.onTouchStart, {
          passive: true
        });
        this.element.removeEventListener("touchmove", this.cancelTouch, {
          passive: true
        });
        this.element.removeEventListener("touchend", this.onTouchEnd);
        this.element.removeEventListener("touchCancel", this.cancelTouch);
      }
      this.element.removeEventListener("mousedown", this.openThread, {
        passive: true
      });
      this.element.removeEventListener("keydown", this.openThread, {
        passive: true
      });
    }
    onTouchStart(event1) {
      this.isActive = true;
      event1.stopPropagation();
      this.touching = true;
    }
    onTouchEnd() {
      this.isActive = false;
      if (this.touching) {
        this.openThread();
      }
    }
    cancelTouch() {
      this.isActive = false;
      this.touching = false;
    }
    openThread(event1) {
      if (event1?.type === "keydown" && event1?.key !== "Enter") {
        return;
      }
      // handle middle mouse
      if (event1?.type === "mousedown" && (event1?.which === 2 || event1?.shiftKey)) {
        window.open((0, _getUrl.default)(this.router.urlFor("chat.channel.thread", ...this.args.message.thread.routeModels)), "_blank");
        return;
      }
      this.chat.activeMessage = null;
      this.router.transitionTo("chat.channel.thread", ...this.args.message.thread.routeModels);
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        class={{concatClass
          "chat-message-thread-indicator"
          (if this.isActive "-active")
        }}
        {{didInsert this.setup}}
        {{willDestroy this.teardown}}
        role="button"
        title={{i18n "chat.threads.open"}}
        tabindex="0"
        ...attributes
      >
  
        <div class="chat-message-thread-indicator__last-reply-avatar">
          <ChatUserAvatar
            @user={{@message.thread.preview.lastReplyUser}}
            @avatarSize="small"
            @interactive={{this.interactiveUser}}
          />
        </div>
  
        <div class="chat-message-thread-indicator__last-reply-info">
          <span class="chat-message-thread-indicator__last-reply-username">
            {{@message.thread.preview.lastReplyUser.username}}
          </span>
          <span class="chat-message-thread-indicator__last-reply-timestamp">
            {{formatDate
              @message.thread.preview.lastReplyCreatedAt
              leaveAgo="true"
            }}
          </span>
        </div>
        <div class="chat-message-thread-indicator__replies-count">
          {{i18n "chat.thread.replies" count=@message.thread.preview.replyCount}}
        </div>
        <ChatThreadParticipants @thread={{@message.thread}} />
        <div class="chat-message-thread-indicator__last-reply-excerpt">
          {{replaceEmoji (htmlSafe @message.thread.preview.lastReplyExcerpt)}}
        </div>
      </div>
    
  */
  {
    "id": "0KPKhGva",
    "block": "[[[1,\"\\n    \"],[11,0],[16,0,[28,[32,0],[\"chat-message-thread-indicator\",[52,[30,0,[\"isActive\"]],\"-active\"]],null]],[24,\"role\",\"button\"],[16,\"title\",[28,[32,1],[\"chat.threads.open\"],null]],[24,\"tabindex\",\"0\"],[17,1],[4,[32,2],[[30,0,[\"setup\"]]],null],[4,[32,3],[[30,0,[\"teardown\"]]],null],[12],[1,\"\\n\\n      \"],[10,0],[14,0,\"chat-message-thread-indicator__last-reply-avatar\"],[12],[1,\"\\n        \"],[8,[32,4],null,[[\"@user\",\"@avatarSize\",\"@interactive\"],[[30,2,[\"thread\",\"preview\",\"lastReplyUser\"]],\"small\",[30,0,[\"interactiveUser\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"chat-message-thread-indicator__last-reply-info\"],[12],[1,\"\\n        \"],[10,1],[14,0,\"chat-message-thread-indicator__last-reply-username\"],[12],[1,\"\\n          \"],[1,[30,2,[\"thread\",\"preview\",\"lastReplyUser\",\"username\"]]],[1,\"\\n        \"],[13],[1,\"\\n        \"],[10,1],[14,0,\"chat-message-thread-indicator__last-reply-timestamp\"],[12],[1,\"\\n          \"],[1,[28,[32,5],[[30,2,[\"thread\",\"preview\",\"lastReplyCreatedAt\"]]],[[\"leaveAgo\"],[\"true\"]]]],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-thread-indicator__replies-count\"],[12],[1,\"\\n        \"],[1,[28,[32,1],[\"chat.thread.replies\"],[[\"count\"],[[30,2,[\"thread\",\"preview\",\"replyCount\"]]]]]],[1,\"\\n      \"],[13],[1,\"\\n      \"],[8,[32,6],null,[[\"@thread\"],[[30,2,[\"thread\"]]]],null],[1,\"\\n      \"],[10,0],[14,0,\"chat-message-thread-indicator__last-reply-excerpt\"],[12],[1,\"\\n        \"],[1,[28,[32,7],[[28,[32,8],[[30,2,[\"thread\",\"preview\",\"lastReplyExcerpt\"]]],null]],null]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"&attrs\",\"@message\"],false,[\"if\"]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/chat-message-thread-indicator.js",
    "scope": () => [_concatClass.default, _i18n.default, _didInsert.default, _willDestroy.default, _chatUserAvatar.default, _formatDate.default, _chatThreadParticipants.default, _replaceEmoji.default, _htmlSafe.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "capabilities", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "chatStateManager", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "router", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "site", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isActive", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "teardown", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "teardown"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTouchStart", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onTouchStart"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onTouchEnd", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "onTouchEnd"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "cancelTouch", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "cancelTouch"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "openThread", [_decorators.bind], Object.getOwnPropertyDescriptor(_class.prototype, "openThread"), _class.prototype)), _class);
});