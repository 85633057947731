define("discourse/plugins/chat/discourse/connectors/user-preferences-nav/chat-preferences", ["exports", "@glimmer/component", "@ember/routing", "discourse-common/helpers/d-icon", "discourse-common/helpers/i18n", "@ember/template-factory", "@ember/component"], function (_exports, _component, _routing, _dIcon, _i18n, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  class ChatPreferences extends _component.default {
    static shouldRender(_ref, _ref2) {
      let {
        model: model1
      } = _ref;
      let {
        siteSettings: siteSettings1,
        currentUser: currentUser1
      } = _ref2;
      return siteSettings1.chat_enabled && (model1.can_chat || currentUser1?.admin);
    }
  }
  _exports.default = ChatPreferences;
  _class = ChatPreferences;
  (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <li class="user-nav__preferences-chat">
        <LinkTo @route="preferences.chat">
          {{dIcon "d-chat"}}
          <span>{{i18n "chat.title_capitalized"}}</span>
        </LinkTo>
      </li>
    
  */
  {
    "id": "0aFz3uYj",
    "block": "[[[1,\"\\n    \"],[10,\"li\"],[14,0,\"user-nav__preferences-chat\"],[12],[1,\"\\n      \"],[8,[32,0],null,[[\"@route\"],[\"preferences.chat\"]],[[\"default\"],[[[[1,\"\\n        \"],[1,[28,[32,1],[\"d-chat\"],null]],[1,\"\\n        \"],[10,1],[12],[1,[28,[32,2],[\"chat.title_capitalized\"],null]],[13],[1,\"\\n      \"]],[]]]]],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[],false,[]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/connectors/user-preferences-nav/chat-preferences.js",
    "scope": () => [_routing.LinkTo, _dIcon.default, _i18n.default],
    "isStrictMode": true
  }), _class);
});