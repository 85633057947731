define("discourse/plugins/chat/discourse/components/styleguide/organisms/chat", ["exports", "discourse/plugins/chat/discourse/components/styleguide/chat-composer", "discourse/plugins/chat/discourse/components/styleguide/chat-composer-message-details", "discourse/plugins/chat/discourse/components/styleguide/chat-header-icon", "discourse/plugins/chat/discourse/components/styleguide/chat-message", "discourse/plugins/chat/discourse/components/styleguide/chat-modal-archive-channel", "discourse/plugins/chat/discourse/components/styleguide/chat-modal-channel-summary", "discourse/plugins/chat/discourse/components/styleguide/chat-modal-create-channel", "discourse/plugins/chat/discourse/components/styleguide/chat-modal-delete-channel", "discourse/plugins/chat/discourse/components/styleguide/chat-modal-edit-channel-description", "discourse/plugins/chat/discourse/components/styleguide/chat-modal-edit-channel-name", "discourse/plugins/chat/discourse/components/styleguide/chat-modal-move-message-to-channel", "discourse/plugins/chat/discourse/components/styleguide/chat-modal-new-message", "discourse/plugins/chat/discourse/components/styleguide/chat-modal-thread-settings", "discourse/plugins/chat/discourse/components/styleguide/chat-modal-toggle-channel-status", "discourse/plugins/chat/discourse/components/styleguide/chat-thread-list-item", "@ember/template-factory", "@ember/component", "@ember/component/template-only"], function (_exports, _chatComposer, _chatComposerMessageDetails, _chatHeaderIcon, _chatMessage, _chatModalArchiveChannel, _chatModalChannelSummary, _chatModalCreateChannel, _chatModalDeleteChannel, _chatModalEditChannelDescription, _chatModalEditChannelName, _chatModalMoveMessageToChannel, _chatModalNewMessage, _chatModalThreadSettings, _chatModalToggleChannelStatus, _chatThreadListItem, _templateFactory, _component, _templateOnly) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const ChatOrganism = (0, _component.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
    <ChatMessage />
    <ChatComposer />
    <ChatThreadListItem />
    <ChatComposerMessageDetails />
    <ChatHeaderIcon />
  
    <h2>Modals</h2>
  
    <ChatModalArchiveChannel />
    <ChatModalMoveMessageToChannel />
    <ChatModalDeleteChannel />
    <ChatModalEditChannelDescription />
    <ChatModalEditChannelName />
    <ChatModalThreadSettings />
    <ChatModalCreateChannel />
    <ChatModalToggleChannelStatus />
    <ChatModalNewMessage />
    <ChatModalChannelSummary />
  
  */
  {
    "id": "3916F4MG",
    "block": "[[[1,\"\\n  \"],[8,[32,0],null,null,null],[1,\"\\n  \"],[8,[32,1],null,null,null],[1,\"\\n  \"],[8,[32,2],null,null,null],[1,\"\\n  \"],[8,[32,3],null,null,null],[1,\"\\n  \"],[8,[32,4],null,null,null],[1,\"\\n\\n  \"],[10,\"h2\"],[12],[1,\"Modals\"],[13],[1,\"\\n\\n  \"],[8,[32,5],null,null,null],[1,\"\\n  \"],[8,[32,6],null,null,null],[1,\"\\n  \"],[8,[32,7],null,null,null],[1,\"\\n  \"],[8,[32,8],null,null,null],[1,\"\\n  \"],[8,[32,9],null,null,null],[1,\"\\n  \"],[8,[32,10],null,null,null],[1,\"\\n  \"],[8,[32,11],null,null,null],[1,\"\\n  \"],[8,[32,12],null,null,null],[1,\"\\n  \"],[8,[32,13],null,null,null],[1,\"\\n  \"],[8,[32,14],null,null,null],[1,\"\\n\"]],[],false,[]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/styleguide/organisms/chat.js",
    "scope": () => [_chatMessage.default, _chatComposer.default, _chatThreadListItem.default, _chatComposerMessageDetails.default, _chatHeaderIcon.default, _chatModalArchiveChannel.default, _chatModalMoveMessageToChannel.default, _chatModalDeleteChannel.default, _chatModalEditChannelDescription.default, _chatModalEditChannelName.default, _chatModalThreadSettings.default, _chatModalCreateChannel.default, _chatModalToggleChannelStatus.default, _chatModalNewMessage.default, _chatModalChannelSummary.default],
    "isStrictMode": true
  }), (0, _templateOnly.default)());
  var _default = _exports.default = ChatOrganism;
});