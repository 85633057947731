define("discourse/plugins/chat/discourse/components/toggle-channel-membership-button", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/helper", "@ember/object", "@ember/service", "discourse/components/d-button", "discourse/components/plugin-outlet", "discourse/helpers/concat-class", "discourse/lib/ajax-error", "discourse-i18n", "@ember/template-factory", "@ember/component"], function (_exports, _component, _tracking, _helper, _object, _service, _dButton, _pluginOutlet, _concatClass, _ajaxError, _discourseI18n, _templateFactory, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let ToggleChannelMembershipButton = _exports.default = (_class = (_class2 = class ToggleChannelMembershipButton extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "chat", _descriptor, this);
      _initializerDefineProperty(this, "chatApi", _descriptor2, this);
      _initializerDefineProperty(this, "isLoading", _descriptor3, this);
      _defineProperty(this, "options", {});
      this.options = {
        labelType: "normal",
        joinTitle: _discourseI18n.default.t("chat.channel_settings.join_channel"),
        joinIcon: "",
        joinClass: "",
        leaveTitle: _discourseI18n.default.t("chat.channel_settings.leave_channel"),
        leaveIcon: "",
        leaveClass: "",
        ...this.args.options
      };
    }
    get label() {
      if (this.options.labelType === "none") {
        return "";
      }
      if (this.options.labelType === "short") {
        if (this.args.channel.currentUserMembership.following) {
          return _discourseI18n.default.t("chat.channel_settings.leave");
        } else {
          return _discourseI18n.default.t("chat.channel_settings.join");
        }
      }
      if (this.args.channel.currentUserMembership.following) {
        return _discourseI18n.default.t("chat.channel_settings.leave_channel");
      } else {
        return _discourseI18n.default.t("chat.channel_settings.join_channel");
      }
    }
    onJoinChannel() {
      this.isLoading = true;
      return this.chat.followChannel(this.args.channel).then(() => {
        this.args.onJoin?.(this.args.channel);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        if (this.isDestroying || this.isDestroyed) {
          return;
        }
        this.isLoading = false;
      });
    }
    async onLeaveChannel() {
      this.isLoading = true;
      try {
        if (this.args.channel.chatable.group) {
          await this.chatApi.leaveChannel(this.args.channel.id);
        } else {
          await this.chat.unfollowChannel(this.args.channel);
        }
        this.args.onLeave?.(this.args.channel);
      } catch (error1) {
        (0, _ajaxError.popupAjaxError)(error1);
      } finally {
        this.isLoading = false;
      }
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      {{#if @channel.currentUserMembership.following}}
        <DButton
          @action={{this.onLeaveChannel}}
          @translatedLabel={{this.label}}
          @translatedTitle={{this.options.leaveTitle}}
          @icon={{this.options.leaveIcon}}
          @disabled={{this.isLoading}}
          class={{concatClass
            "toggle-channel-membership-button -leave"
            this.options.leaveClass
          }}
        />
      {{else}}
        <PluginOutlet
          @name="chat-join-channel-button"
          @outletArgs={{hash
            onJoinChannel=this.onJoinChannel
            channel=@channel
            icon=this.options.joinIcon
            title=this.options.joinTitle
            label=this.label
            disabled=this.isLoading
          }}
          @defaultGlimmer={{true}}
        >
          <DButton
            @action={{this.onJoinChannel}}
            @translatedLabel={{this.label}}
            @translatedTitle={{this.options.joinTitle}}
            @icon={{this.options.joinIcon}}
            @disabled={{this.isLoading}}
            class={{concatClass
              "toggle-channel-membership-button -join"
              this.options.joinClass
            }}
          />
        </PluginOutlet>
      {{/if}}
    
  */
  {
    "id": "fpYrAY3X",
    "block": "[[[1,\"\\n\"],[41,[30,1,[\"currentUserMembership\",\"following\"]],[[[1,\"      \"],[8,[32,0],[[16,0,[28,[32,1],[\"toggle-channel-membership-button -leave\",[30,0,[\"options\",\"leaveClass\"]]],null]]],[[\"@action\",\"@translatedLabel\",\"@translatedTitle\",\"@icon\",\"@disabled\"],[[30,0,[\"onLeaveChannel\"]],[30,0,[\"label\"]],[30,0,[\"options\",\"leaveTitle\"]],[30,0,[\"options\",\"leaveIcon\"]],[30,0,[\"isLoading\"]]]],null],[1,\"\\n\"]],[]],[[[1,\"      \"],[8,[32,2],null,[[\"@name\",\"@outletArgs\",\"@defaultGlimmer\"],[\"chat-join-channel-button\",[28,[32,3],null,[[\"onJoinChannel\",\"channel\",\"icon\",\"title\",\"label\",\"disabled\"],[[30,0,[\"onJoinChannel\"]],[30,1],[30,0,[\"options\",\"joinIcon\"]],[30,0,[\"options\",\"joinTitle\"]],[30,0,[\"label\"]],[30,0,[\"isLoading\"]]]]],true]],[[\"default\"],[[[[1,\"\\n        \"],[8,[32,0],[[16,0,[28,[32,1],[\"toggle-channel-membership-button -join\",[30,0,[\"options\",\"joinClass\"]]],null]]],[[\"@action\",\"@translatedLabel\",\"@translatedTitle\",\"@icon\",\"@disabled\"],[[30,0,[\"onJoinChannel\"]],[30,0,[\"label\"]],[30,0,[\"options\",\"joinTitle\"]],[30,0,[\"options\",\"joinIcon\"]],[30,0,[\"isLoading\"]]]],null],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[]]],[1,\"  \"]],[\"@channel\"],false,[\"if\"]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/toggle-channel-membership-button.js",
    "scope": () => [_dButton.default, _concatClass.default, _pluginOutlet.default, _helper.hash],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "chat", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "chatApi", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isLoading", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "onJoinChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onJoinChannel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onLeaveChannel", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onLeaveChannel"), _class.prototype)), _class);
});