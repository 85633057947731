define("discourse/plugins/chat/discourse/components/dc-filter-input", ["exports", "@glimmer/component", "@glimmer/tracking", "@ember/component", "@ember/modifier", "ember-modifier", "discourse/helpers/concat-class", "discourse/helpers/noop", "discourse-common/helpers/d-icon", "@ember/template-factory"], function (_exports, _component, _tracking, _component2, _modifier, _emberModifier, _concatClass, _noop, _dIcon, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _class2;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : String(i); }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  let DcFilterInput = _exports.default = (_class = (_class2 = class DcFilterInput extends _component.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "isFocused", _descriptor, this);
      _defineProperty(this, "focusState", (0, _emberModifier.modifier)(element1 => {
        const focusInHandler1 = () => {
          this.isFocused = true;
        };
        const focusOutHandler1 = () => {
          this.isFocused = false;
        };
        element1.addEventListener("focusin", focusInHandler1);
        element1.addEventListener("focusout", focusOutHandler1);
        return () => {
          element1.removeEventListener("focusin", focusInHandler1);
          element1.removeEventListener("focusout", focusOutHandler1);
        };
      }));
    }
  }, (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
  /*
    
      <div
        class={{concatClass
          @containerClass
          "dc-filter-input-container"
          (if this.isFocused "is-focused")
        }}
      >
        {{#if @icons.left}}
          {{icon @icons.left class="-left"}}
        {{/if}}
  
        <Input
          {{this.focusState}}
          {{on "input" (if @filterAction @filterAction (noop))}}
          @value={{@value}}
          class="dc-filter-input"
          ...attributes
        />
  
        {{yield}}
  
        {{#if @icons.right}}
          {{icon @icons.right class="-right"}}
        {{/if}}
      </div>
    
  */
  {
    "id": "FwFg/KwA",
    "block": "[[[1,\"\\n    \"],[10,0],[15,0,[28,[32,0],[[30,1],\"dc-filter-input-container\",[52,[30,0,[\"isFocused\"]],\"is-focused\"]],null]],[12],[1,\"\\n\"],[41,[30,2,[\"left\"]],[[[1,\"        \"],[1,[28,[32,1],[[30,2,[\"left\"]]],[[\"class\"],[\"-left\"]]]],[1,\"\\n\"]],[]],null],[1,\"\\n      \"],[8,[32,2],[[24,0,\"dc-filter-input\"],[17,3],[4,[30,0,[\"focusState\"]],null,null],[4,[32,3],[\"input\",[52,[30,5],[30,5],[28,[32,4],null,null]]],null]],[[\"@value\"],[[30,4]]],null],[1,\"\\n\\n      \"],[18,6,null],[1,\"\\n\\n\"],[41,[30,2,[\"right\"]],[[[1,\"        \"],[1,[28,[32,1],[[30,2,[\"right\"]]],[[\"class\"],[\"-right\"]]]],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[\"@containerClass\",\"@icons\",\"&attrs\",\"@value\",\"@filterAction\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "/opt/bitnami/discourse/app/assets/javascripts/discourse/discourse/plugins/chat/discourse/components/dc-filter-input.js",
    "scope": () => [_concatClass.default, _dIcon.default, _component2.Input, _modifier.on, _noop.default],
    "isStrictMode": true
  }), _class2), _class2), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isFocused", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  })), _class);
});